.headingText {
    color: #262626 !important;
    font-family: 'Circular-700' !important;
    font-size: 18px !important;
    font-style: normal !important;
    line-height: normal !important;
    word-spacing: -5px;
  }

  .currentPlanContainer{
    background-color: #262626 !important;
    padding: 20px !important;
    border-radius: 5px !important;
    position: relative !important;
  }
  .currentPlanRightTick{
    position: absolute !important;
    top: -17.5px !important;
    right: -17.5px !important;
  }

  .currentPlanName{
    color: #f6f6f6 !important;
    font-family: 'Circular-700' !important;
    font-size: 18px !important;
    font-style: normal !important;
    line-height: normal !important;
    word-spacing: -5px;
  }
  .currentPlanDaysRemaining, .currentPlanStarted{
    color: #f6f6f6 !important;
    font-family: 'Circular-900' !important;
    word-spacing: -5px;
  }
  .currentPlanButton, .currentPlanButtonOpacity {
    border-radius: 5.191px !important;
    width: 100% !important;
    background: #f6f6f6 !important;
    color: #262626 !important;
    text-align: center !important;
    font-family: 'Circular-400' !important;
    font-size: 14px;
    font-style: normal;
    font-weight: 450;
    /* opacity: 0.7; */
  }
  .currentPlanButtonOpacity{
    opacity: 0.7;
  }
  .currentPlanStarted{
    color: #7b7b7b !important;
    word-spacing: -3px !important;
  }
  .currentPlanAmount{
    color: #f6f6f6 !important;
    font-family: 'Circular-400' !important;
    font-size: 20px !important;
  }
  .currentPlanbillingPeriod{
    color: #7b7b7b !important;
    font-size: 14px !important;
    font-family: 'Circular-700' !important;
  }