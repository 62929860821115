.countryDetails {
  color: #262626 !important;
  font-family: 'Circular-400' !important;
  font-size: 15px !important;
  font-weight: 450 !important;
  line-height: normal !important;
  word-spacing: -4px;
}
.countryDetailsContainer {
  margin: 15px 0px !important;
}
.autoCompleteDropdown .ant-select-item-option-content {
    word-spacing: -3px !important;
}
.autoCompleteInput{
    height: 30 !important;
    line-height: 20px !important;
    font-family: 'Circular-900' !important;
    padding-top: 7px !important;
}
.autoCompleteInput ::placeholder{
    font-family: 'Circular-400' !important; 
    word-spacing: -3px !important;
    font-size: 14px !important; 
    font-weight: normal !important;
}