.successTextColor {
  color: #219653 !important;
}
.errorTextColor {
  color: #eb5757 !important;
}
.orderDetailContainer{
  margin-top: 5px !important;
  border: 1px solid #bdbdbd !important;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  padding: 15px 0 !important;
  margin-bottom: 20px !important;
}
.orderDetailheadings{
  text-align: center !important;
  font-family: 'Circular-400' !important;
  font-size: 17px !important;
  word-spacing: -4px !important;
}
.orderDetailSubheadings{
  text-align: center !important;
}
.orderDetailHead{
  color: #7b7b7b !important;
  font-family: 'Circular-400' !important;
  word-spacing: -4px !important;
  font-size: 13px !important;
}
.orderDetailText{
  font-family: 'Circular-400' !important;
  word-spacing: -4px !important;
  font-size: 16px !important;
}
.orderDetailDivider{
  border: 2px dashed #bdbdbd !important;
  margin: 15px 0 !important;
}
.orderDetailItemDetailText{
  color: #7b7b7b !important;
  font-family: 'Circular-400' !important;
  word-spacing: -4px !important;
  font-size: 16px !important;
}
.orderDetailItemDivider{
  margin: 15px 0 !important
}

