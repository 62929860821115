.editButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 156px;
  height: 48px;
  background: #347af0;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #f5f5f7;
}
/* .cancelButton {
  background: #ffffff;
  box-shadow: 0px 1px 120px rgba(0, 0, 0, 0.12);
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #347af0;
  width: 100% !important;
  height: 48px;
} */
.publishedColor {
  color: rgb(176, 22, 22);
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 17px;
}
.unpublishedColor {
  color: rgb(13, 133, 81);
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 17px;
}
.publishDetail {
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 17px;
  color: #828282 !important;
}
.ant-typography ul {
  list-style-type: disc;
}
.breadcrumRestaurant {
  color: #7b7b7b !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
  letter-spacing: 0.15px;
  word-spacing: -5px;
  cursor: pointer;
}
.breadcrumUsers {
  color: #262626 !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
  letter-spacing: 0.15px;
  word-spacing: -5px;
}
.detailsPage {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #303134;
}
.detailsPage2 {
  font-family: 'Montserrat' !important;
  font-style: normal;
  font-weight: 700 !important;
  font-size: 14px;
  line-height: 17px;
  color: #828282 !important;
}
.selectedButton {
  background-color: '#E5E5E5' !important;
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  border-bottom: 1px solid #262626 !important;
  word-spacing: -3px;
  min-height: 38px !important;
  padding: 12px;
}
/* .selectedButton2 {
  padding: 10px !important;
  width: 100% !important;
  background-color: '#f6f6f6' !important;
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  border-bottom: 1px solid #262626 !important;
  min-height: 35px !important;
  min-width: 100px !important;
} */
/* .ant-tabs-tab {
  margin: 0 !important;
} */
/* .unselectedButton2 {
  padding: 10px !important;
  width: 100% !important;
  background-color: '#f6f6f6' !important;
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  min-height: 35px !important;
  min-width: 100px !important;
  border-bottom: 1px solid #a9aaad !important;
} */
.unselectedButton {
  background-color: '#E5E5E5' !important;
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  word-spacing: -3px;
  min-height: 38px !important;
  padding: 12px;
  border-bottom: 1px solid #a9aaad !important;
}

/*This might be use in future */
/* .ant-tabs-nav-wrap {
  overflow: visible !important;
} */
.Tabs .ant-tabs {
  overflow: hidden !important;
  overflow-x: scroll !important;
}
/* .selectedButtonant-btn:hover,
.ant-btn:focus {
  border-color: #e3e3e3 !important;
} */
.hideBottomLine.ant-tabs > .ant-tabs-nav .ant-tabs-nav-more,
.ant-tabs > div > .ant-tabs-nav .ant-tabs-nav-more {
  display: none;
}
/* .cMTabsBckground .ant-tabs-ink-bar {
  height: 0px !important;
  background: transparent !important;
} */
/* .cMTabsBckground .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0px solid black !important;
} */
/* .ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 0px solid #a9aaad !important;
} */
.cMTabsBckground .ant-tabs-tab {
  padding: 0px !important;
}

/* .cMTabsBckground .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0px solid black !important;
} */

.editDesignationButton,
.editButton {
  font-feature-settings: 'clig' off, 'liga' off;
  line-height: 150%;
  border-radius: 5px !important;
  font-family: 'Circular-400' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  align-items: center;
  text-align: center;
  color: #ffffff !important;
  text-transform: none !important;
  box-shadow: unset !important;
  width: 100% !important;
  background-color: #262626 !important;
  border-color: #262626 !important;
}

/* .editDesignationButton.ant-btn:hover,
.ant-btn:focus {

  color: #000000 !important;
} */

.editDesignationButton2,
.editButton {
  color: #262626 !important;
  font-feature-settings: 'clig' off, 'liga' off;
  border-radius: 5px !important;
  font-family: 'Circular-500' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 20px !important;
  align-items: center;
  text-align: center;
  text-transform: none !important;
  box-shadow: unset !important;
  width: 100% !important;
  border-color: #262626 !important;
}
.sidebarTitle {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  letter-spacing: 0.3px;
  color: #f7f3f3;
  display: flex;
  height: 100%;
  align-items: center;
}
.sidebar {
  font-family: 'Circular-500' !important;
  font-style: normal !important;
  font-weight: 550 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  text-align: center !important;
  color: #f2f2f2 !important;
}
.restaurant {
  font-family: 'Circular-400' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: normal !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  background-color: transparent !important;
  display: inline-flex;
}

.itemTypes {
  font-family: 'Circular-400' !important;
  font-style: normal !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: normal !important;
  color: #ffffff !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  background-color: transparent !important;
  display: inline-flex;
  word-spacing: -4px !important;
}

.heading {
  color: #262626 !important;
  font-family: 'Circular-700' !important;
  font-size: 21px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: normal !important;
  word-spacing: -5px;
  /* text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25) !important; */
}
.selection.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f6f6f6 !important;
  border-radius: 0px;
  border: 1px solid #7b7b7b !important;
}
.ant-table-row {
  background-color: #f6f6f6 !important;
  border: hidden !important;
  color: #262626 !important;
  font-family: 'Circular-400' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.ant-table-small .ant-table-thead > tr > th {
  background-color: #f6f6f6 !important;
  border: hidden !important;
  color: #7b7b7b !important;
  font-family: 'Circular-400' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}
.ant-pagination-item-link,
.ant-pagination-item,
.ant-pagination-item-active a {
  background-color: #f6f6f6 !important;
  border-color: #7b7b7b !important;
  color: #262626 !important;
}

.inputLabel {
  border: 1px solid #262626 !important;
  background: #f6f6f6 !important;
  font-family: 'Circular-900' !important;
  word-spacing: -3px !important;
}
.inputLabel3 {
  border: 1px solid #7b7b7b !important;
  background: #f6f6f6 !important;
}
.inputNumber {
  border: 1px solid #7b7b7b !important;
  background: #f6f6f6 !important;
  border-radius: 4px !important;
  font-family: 'Circular-400' !important;
  text-align: center !important;
  font-size: 14px !important;
}

.inputNumber svg {
  vertical-align: middle !important;
}
.warning {
  color: #262626 !important;
  text-align: center !important;
  font-family: 'Circular-400' !important;
  font-size: 21.094px !important;
  font-style: normal !important;
  font-weight: 700 !important;
  line-height: 37.197px !important;
}
.ant-popover-inner {
  border-radius: 5px !important;
}
.popconfirmButton {
  width: 100px;
  height: 30px !important;
  border-radius: 5px !important;
  border-color: #262626 !important;
  background: #262626 !important;
  color: #e5e5e5 !important;
  text-align: center !important;
  font-family: 'Circular-400' !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 500 !important;
  line-height: normal !important;
}
.ant-popover-buttons {
  text-align: center !important;
}

.popconfirmTitle {
  color: #262626 !important;
  text-align: center !important;
  font-family: 'Circular-400' !important;
  font-size: 14.458px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 26.368px !important;
  color: #262626;
  word-spacing: -5px;
}
.ant-popover-message-title {
  padding: 2px;
}
/* This code can be use in future */
/* .filterButton.ant-btn:hover,
.ant-btn:focus {
  color: #262626 !important;
} */
/* .ant-checkbox-inner {
  border: 1px solid #7b7b7b !important;
  background: #f6f6f6 !important;
} */
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #262626 !important;
  border-color: #262626 !important;
}
input:focus + .ant-checkbox-inner {
  border-color: #262626 !important;
}
.ant-checkbox-checked::after {
  border: 1px solid #262626 !important;
}

.custom-tabs {
  margin-right: 10px;
}
.card {
  width: 100%;
  border: 1px solid #7b7b7b !important;
  background: #e5e5e5 !important;
}
.text {
  font-family: 'Circular-400' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

Customisable.css.editButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 12px;
  width: 156px;
  height: 48px;
  background: #347af0;
  border-radius: 4px;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 180%;
  text-align: center;
  letter-spacing: 0.5px;
  color: #f5f5f7;
}
/* .cMTabsBckground .ant-tabs-top > .ant-tabs-nav::before,
.ant-tabs-bottom > .ant-tabs-nav::before,
.ant-tabs-top > div > .ant-tabs-nav::before,
.ant-tabs-bottom > div > .ant-tabs-nav::before {
  border-bottom: 0px solid black !important;
} */

/* .editDesignationButton.ant-btn:hover,
.ant-btn:focus {
  color: #ffffff !important;
} */

.selection.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  background-color: #f6f6f6 !important;
  border-radius: 0px;
  border: 1px solid #7b7b7b !important;
}
.ant-table-row {
  /* background-color: #e5e5e5 !important; */
  /* border:hidden !important; */
  color: #262626 !important;
  font-family: 'Circular-400' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}

.ant-table-small .ant-table-thead > tr > th {
  /* background-color: #e5e5e5 !important; */
  border: hidden !important;
  color: #7b7b7b !important;
  font-family: 'Circular-400' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 18px !important;
}
.ant-pagination-item-link,
.ant-pagination-item,
.ant-pagination-item-active a {
  /* background-color: #e5e5e5 !important; */
  border-color: #7b7b7b !important;
  color: #7b7b7b !important;
}

.formHeading {
  font-family: 'Circular-400' !important;
  font-size: 16px;
  font-weight: 200;
  word-spacing: -5px;
  line-height: 24px;
  text-align: left;
  font-style: normal;
  color: #262626 !important;
}
/* .inputLabel{
  border: 1px solid #7b7b7b !important;
  background: #e5e5e5 !important;
} */
.inputLabel2 {
  border: 1px solid #7b7b7b !important;
  background: rgba(246, 246, 246, 1);
}
.ant-popover-message-title {
  padding: 2px;
}
/* .filterButton.ant-btn:hover,
.ant-btn:focus {
  color: #262626 !important;
} */
.ant-checkbox-inner {
  border: 1px solid #7b7b7b !important;
  background: rgba(246, 246, 246, 1) !important;
}
.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #262626 !important;
  border-color: #262626 !important;
}
input:focus + .ant-checkbox-inner {
  border-color: #262626 !important;
}
.ant-checkbox-checked::after {
  border: 1px solid #262626 !important;
}

.custom-tabs {
  margin-right: 10px;
}
.buttonStation.ant-btn {
  cursor: pointer;
  border: none;
}
.checkBox {
  margin: 5px !important;
  margin-left: 16px !important;
  background-color: #347af0 !important;
}
.ant-checkbox-group-item {
  margin-bottom: 10px !important;
}

.text {
  font-family: 'Circular-400' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
}
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}

.row-dragging td {
  padding: 13px;
}

.row-dragging .drag-visible {
  visibility: visible;
}
.drag-visible {
  width: 3%;
}
.drag-visible-name {
  width: 18%;
}
.drag-visible-position {
  width: 8%;
}
.drag-visible-total-item {
  width: 6%;
}
.drag-visible-action {
  width: 5%;
}
.image-container {
  display: flex;
  gap: 10px;
  margin: 20px;
}

img {
  cursor: pointer;
  transition: transform 0.3s, filter 0.3s;
}

img.active {
  transform: scale(1.1);
  filter: grayscale(150%);
}

img.inactive {
  transform: scale(0.9);
  filter: grayscale(100%);
}
.disableEvents {
  pointer-events: none !important;
  opacity: 0.4 !important;
}
.buttonadd .ant-btn-icon-only {
  background-color: rgba(246, 246, 246, 1) !important;
  border: none !important;
}

.disabled {
  color: #000000 !important;
  opacity: 0.5 !important;
  cursor: no-drop !important;
}

.drag-visible2 {
  width: 4%;
}

.drag-visible-position2 {
  width: 7%;
}
.drag-visible-name2 {
  width: 35%;
}
.drag-visible3 {
  width: 10%;
}
.drag-visible-position3 {
  width: 16%;
}
.drag-visible-name3 {
  width: 58%;
}
.drag-visible-price {
  width: 14%;
}
.drag-visible-quantity {
  width: 13%;
}
.drag-visible-action3 {
  width: 22%;
}
.drag-visible4 {
  width: 11%;
}
.drag-visible-position4 {
  width: 16%;
}
.drag-visible-question-text {
  width: 25%;
}
.drag-visible-question-type {
  width: 12%;
}

.drag-visible-action4 {
  width: 9%;
}

.drag-visible-survey-question {
  width: 6%;
}
.drag-visible-position-survey-question {
  width: 6%;
}

.ant-modal {
  border-radius: 5px !important;
  overflow: hidden;
  text-align: center !important;
}
.ant-modal-content {
  background-color: #f6f6f6 !important;
}
.ant-modal-header {
  border-bottom: none !important; /* Remove the divider line */
  background-color: #f6f6f6 !important;
}
.ant-modal-title {
  width: 100% !important;
}
.ant-modal-footer {
  border-top: none !important;
  text-align: center !important; /* Remove the border of the footer */
}
.modalTitle {
  color: #262626 !important;
  font-family: 'Circular-500' !important;
  font-size: 17.458px !important;
  font-style: normal !important;
  line-height: 26.368px !important;
  color: #262626;
  word-spacing: -5px;
}
.modalDescription, .subscriptionModalDescription{
  color: #262626 !important;
  text-align: center !important;
  font-family: 'Circular-400' !important;
  font-size: 15px !important;
  font-style: normal !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  color: #262626;
  word-spacing: -5px;
  margin: 25px 0px -45px 0px !important
}
.subscriptionModalDescription{
  margin: 25px 0px -0px 0px !important
}
.modalPicker{
  margin: 10px 0px -45px 0px !important;
  border-radius: 5px !important;
  width: 100%;
}
.subscriptionDatepicker{
  width: 70% !important;
  font-family: 'Circular-400' !important;
  border-radius: 5px !important;
}
.ant-picker-input > input {
  font-family: 'Circular-400';
}
.ant-picker-panel {
  font-family: 'Circular-400';
}
