.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }

  .emailInput {
    background: #f5f6f8 !important;

    box-sizing: border-box !important;
    border-radius: 4px !important;
    height: 55px !important;
  }
  .loginInput.ant-input-affix-wrapper > input.ant-input {
    background: #f5f6f8 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;

    height: 45px !important;
    max-width: 384px;
  }
  .loginInput.ant-input-affix-wrapper {
    background-color: #f5f6f8 !important;
  }
  .signUpSelector.ant-select:not(.ant-select-customize-input)
    .ant-select-selector {
    background: #f5f6f8 !important;
    box-sizing: border-box !important;
    border-radius: 4px !important;
    height: 56px !important;
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
  }
  .loginBtn {
    font-family: Montserrat !important;
    font-style: normal !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
    text-align: center !important;
    letter-spacing: 0.5px !important;
    height: 56px !important;
    color: #ffffff !important;
    background-color: #262626 !important;
    border-radius: 4px !important;
    min-width: 178px !important;
  }
  img.imgMain {
    position: absolute;
    left: -22%;

    width: 50%;
    opacity: 0.5;
    top: -2%;
    margin: auto;
  }
  .LandingPageLayout::-webkit-scrollbar {
    display: none !important;
  }
  .selectPlaceholder {
    font-family: Montserrat !important;
    font-weight: 500 !important;
    line-height: 17px !important;
    padding-top: 20px !important;
    letter-spacing: 0.4375px;
    color: rgba(39, 39, 39, 0.4) !important;
  }
}
.uploadImageSignUpInput .ant-input-affix-wrapper {
  background: #f5f6f8 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  padding: 0px 11px !important;
}
.ant-input {
  background-color: #f6f6f6 !important;
}
input.ant-input.inputLabel{
  box-shadow: none !important;
}
.uploadImageSignUpInput .ant-input-affix-wrapper > input.ant-input {
  background: #f5f6f8 !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
  height: 56px !important;
  padding: 0px 11px !important;
}
.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
.App-link {
  color: #61dafb;
}
div.imageContainer {
  width: 80%;
  height: 100vh;
  text-align: center;
  left: -37%;
  position: absolute;
}
img.Image {
  width: 678px;
  display: inline-block;
  vertical-align: middle;
  opacity: 0.5;
  left: auto;
}
.spanElement {
  display: inline-block;
  vertical-align: middle;
  height: 100%;
}
.loginDiv.ant-row {
  justify-content: end !important;
  align-items: center !important;
  height: 120vh !important;
  width: 80% !important;
}
.ant-upload.ant-upload-select-picture-card {
  width: 210px !important;
  /* height: 100px !important; */
  margin-right: 8px;
  margin-bottom: 8px;
  text-align: center;
  vertical-align: top;
  background-color: #fafafa;
  border: 1px dashed #d9d9d9;
  border-radius: 2px;
  cursor: pointer;
  /* -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s; */
}
/* This code can be use in future  */
/* .react-tel-input .form-control {
  width: 100% !important;
  background: #f5f6f8 !important;
  box-sizing: border-box !important;
  border-radius: 0px !important;
  height: 30px !important;
  border: 1px solid #7b7b7b !important;
  background: #e5e5e5 !important;
} */
.MainLoadingIndicator .ant-spin-nested-loading > div {
  height: 100% !important;
  max-height: unset !important;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@media (max-width: 1024px) {
  img.imgMain {
    position: absolute;
    left: -22%;
    width: 50%;
    opacity: 0.5;
    top: 16%;
  }
}
@media (max-width: 850px) {
  img.Image {
    width: 678px;
    display: inline-block;
    vertical-align: middle;
    opacity: 0.5;
    left: auto;
    display: none;
  }
}
@media (max-width: 768px) {
  img.imgMain {
    position: absolute;
    left: -22%;
    width: 50%;
    opacity: 0.5;
    top: 22%;
  }
}
.headerTitle {
  font-family: 'Circular-400' !important;
  font-size: 24px;
  font-weight: 600;
  line-height: 35.42px;
  text-align: center !important;
}
.pageContent {
  font-family: Montserrat;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 23px;
  letter-spacing: 0.2625px;
  color: rgba(39, 39, 39, 0.85);
}
iframe#webpack-dev-server-client-overlay {
  display: none !important;
}
.content_view {
  margin: 15px 40px 40px 40px !important;
}
.menubar.ant-menu-item-active {
  background-color: #938e8e !important;
  text-shadow: 0px 4px 4px rgba(21, 20, 20, 0.25);
}
.button {
  margin-top: 15px;
}
.tableCard {
  background-color: rgba(38, 38, 38, 1) !important;
  border-radius: 5.191px !important;
  border: 0.727px solid #262626 !important;
  font-family: Circular Std !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  line-height: 20px !important;
  letter-spacing: 0em !important;
  text-align: center !important;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.58) !important;
}
.tablenumber {
  background-color: #262626 !important;
  color: rgba(229, 229, 229, 1) !important;
  padding: 40px !important;
}
.tableSelect{
  border-radius: 5px !important;
  /* width: 170px; */
  font-family: 'circular-400';
  word-spacing: -5px;
}
.ant-tabs-ink-bar {
  position: relative !important;
  background-color: #e5e5e5 !important;
  /* pointer-events: none; */
}
.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border: none !important;
}
.ant-pagination-item {
  border: none !important;
  height: 33px !important;
}

.ant-pagination-item-active {
  border: 1px solid #d9d9d9 !important;
  font-family: 'Circular-400' !important;
  font-size: 13px;
}
.ant-pagination-item a {
  color: rgba(0, 0, 0, 0.85);
  transition: none;
  font-family: 'Circular-400' !important;
  font-size: 13px;
}
.pagination-item-active a {
  /* background-color: #e5e5e5 !important; */
  /* border-color: #7b7b7b !important; */
  color: #7b7b7b !important;
  font-family: 'Circular-400' !important;
  font-size: 13px !important;
}
.pagination {
  margin-top: 20px !important;
}
.ant-table-thead
  > tr
  > th:not(:last-child):not(.ant-table-selection-column):not(
    .ant-table-row-expand-icon-cell
  ):not([colspan])::before {
  position: relative !important;
}
.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  /* border: 1px solid #7b7b7b !important; */
  background: #f6f6f6 !important;
  border: 1px solid #7b7b7b !important;
}
.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: #e5e5e5 !important;
}
.ant-select:not(.ant.select-disabled):hover.ant-select-selector {
  border-color: #f5f6f8 !important;
}
.ant-select-focused:not(ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  ).ant-select-selector {
  border-color: #f5f6f8 !important;
}
.ant-card-body {
  padding: 10px !important;
  background-color: #f6f6f6 !important;
  /* border: 1px solid rgba(38, 38, 38, 1) !important; */
}
.ant-card-body::before {
  border-radius: 40px 0px 0px 40px !important;
}
.ant-select-selector{
  border-radius: 4px !important;
}
