.useViewThemePreviewCategory {
  padding: 4px 8px;
  border-radius: 12px;
  font-size: 12px;
  font-family: 'Circular-900';
}

.userViewThemeCategoryTypeImage {
  background-color: #ffe9c8;
  padding: 5px;
  border-radius: 50%;
}

.userViewThemeCategoryTypeName {
  font-size: 10px;
  font-family: 'Circular-900';
}

.appLogoByName {
  padding: 5px 15px;
  background-color: white;
  border-radius: 50%;
  font-size: 18px;
  font-family: 'Circular-700';
}

.userViewThemePreviewHeaderTitle {
  margin-left: 10px;
  font-family: 'Circular-500';
  color: #ffffff !important;
  word-spacing: -5px;
  font-size: 16px;
}

.userViewThemeCategoryTypeContainer{
    position: absolute;
}

.userViewThemePreviewRecommended{
    font-family: 'Circular-900';
    word-spacing: -3px;
}

.userViewThemePreviewCategoryName{
    font-family: 'Circular-700';
    font-size: 12px;
    word-spacing: -4px;
}

.userViewThemePreviewCategoryDescription{
    font-family: 'Circular-900';
    font-size: 10px;
    word-spacing: -4px;
}

.userViewThemeFooter{
    padding: 10px;
    border-radius: 40px;
}

.userViewThemeFooterCountContainer{
    padding: 5px 10px;
    border-radius: 50%;
    font-family: 'Circular-500';
    margin-right: 8px;
}

.userViewThemePreviewSelectedCategoryName{
  color: white !important;
}

