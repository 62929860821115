.verify {
  font-family: 'Circular-500';
  font-size: 20px;
  margin-top: 10px;
  word-spacing: -5px;
}

.resendButton {
  padding: 0;
  color: #191d23 !important;
  text-decoration: underline !important;
  font-family: 'Circular-400' !important;
}

.resendButton:disabled {
  color: #606775 !important;
}

.otp {
  font-family: 'Circular-900' !important;
  font-size: 16px;
  font-weight: 200;
  text-align: left;
  word-spacing: -5px;
  line-height: 12px;
  font-style: normal;
  color: #262626 !important;
}

.failMsg {
  font-family: 'Circular-900' !important;
  text-align: left;
  font-size: 14px;
  word-spacing: -4px;
}

.otpInput {
  font-family: 'Circular-400' !important;
  background: #f5f6f8 !important;
  border: 1px solid #dedede !important;
  box-sizing: border-box !important;
  border-radius: 4px !important;
}
.otpFocusInput {
  border: #0064ff !important;
}
div.ImageContainer {
  width: 80%;
  height: 100vh;
  text-align: center;
  left: -37%;
  position: absolute;
  top: -3%;
}

.editVerifybtn {
  width: 100% !important;
  align-items: center;
  margin-top: 12px;
  border-radius: 5px !important;
  font-family: 'Circular-900' !important;
  font-style: normal !important;
  font-weight: 500 !important;
  font-size: 16px !important;
  color: #ffffff !important;
  background-color: #262626 !important;
  border-color: #262626 !important;
}
.editVerifybtn:disabled {
  opacity: 0.6
}