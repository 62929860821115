.tabs {
  position: relative;
  display: inline-block;
  flex: 1 1 auto;
  white-space: nowrap;
  overflow: auto hidden;
}
.ant-tabs-tab:last-of-type {
  display: inline !important;
  margin-right: 0;
  margin-left: 0;
  border: none;
}
.tabs .ant-btn {
  background: #f6f6f6 !important;
  border: none !important ;
}
.menu {
  height: 100%;
  background-color: #262626 !important;
}
.editPhotoContainer {
  position: absolute !important;
  bottom: -1% !important;
}
.UsersPhoneInput .form-control {
  width: 100% !important;
  /* height: 40px !important; */
  border: 1px solid #7b7b7b;
  background: #f6f6f6 !important;
}
/* This might use in future */
/* .form {
  height: 500px !important;
  width: 550px !important;
} */
.ant-drawer-close {
  left: 0 !important;
  text-align: start !important;
}
.drawer .ant-drawer-title {
  margin-left: 30px !important;
}
.drawer2 .ant-drawer-title {
  margin-left: 30px !important;
  margin-top: 5px !important;
}
.detailsButton {
  border-radius: 5.191px !important;
  border: 0.727px solid #262626 !important;
  width: 100% !important;
  background: #262626 !important;
  color: #f6f6f6 !important;
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important; */
}
.cancelButton,
.cancelButtonDirty {
  border-radius: 5.191px !important;
  /* border: 0.727px solid #262626 !important; */
  width: 100% !important;
  background: #e0e0e0 !important;
  color: #262626 !important;
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important; */
}
.cancelButtonDirty {
  opacity: 0.6 !important;
}
.classButton {
  border-radius: 5.191px !important;
  border: 0.727px solid #262626 !important;
  width: 100% !important;
  background: #262626 !important;
  color: #f6f6f6 !important;
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
  opacity: 0.7;
}

.detailsButton.ant-btn:focus {
  color: #e5e5e5 !important;
}
.ant-table-tbody > tr > td {
  border: hidden !important;
}
.editButtonDrawer {
  border-radius: 5.191px !important;
  border: 0.727px solid #262626 !important;
  width: 100% !important;
  background: #262626 !important;
  color: #f6f6f6 !important;
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
}

.deleteButtonDrawer {
  border-radius: 5.191px !important;
  border: 0.727px solid #262626 !important;
  width: 100% !important;
  margin-left: 20px !important;
  text-align: center !important;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
  background-color: white !important;
}
.deleteButtonDrawer.ant-btn:focus {
  color: black !important;
}

.filterButton {
  border-radius: 5.191px !important;
  border: 0.727px solid #262626 !important;
  width: 100% !important;
  /* background-color: "#E5E5E5" !important; */
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
  /* box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25) !important; */
  background-color: #f6f6f6 !important;
}
.filterButton.ant-btn:focus {
  color: black !important;
}
.detailsButton img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.filterButton img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}
.image {
  border-radius: 4px !important;
  margin: 20px !important;
}
.ant-avatar > img {
  object-fit: contain !important;
}
.detailheading {
  overflow: hidden;
  color: #7b7b7b !important;
  text-overflow: ellipsis !important;
  font-family: 'Circular-400' !important;
  font-size: 14px !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
}
.detailsubheading {
  overflow: hidden !important;
  color: #262626 !important;
  text-overflow: ellipsis !important;
  font-family: 'Circular-400' !important;
  font-size: 16px !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
  word-spacing: -4px;
}
.ant-upload.ant-upload-select-picture-card {
  border: 1px solid #7b7b7b !important;
  background: #f6f6f6 !important;
}
.ant-upload-select-picture-card {
  float: left !important;
}
/* This code can be use in future */
/* .react-tel-input .selected-flag {
  border: 1px solid #7b7b7b !important;
  background: #e5e5e5 !important;
}*/

.ant-radio-inner::after {
  background-color: #000 !important;
}
.ant-radio-checked .ant-radio-inner {
  border-color: #000 !important;
}
.ant-radio-inner {
  border: 1px solid #7b7b7b !important;
  background: #f6f6f6 !important;
}
.ant-radio-wrapper:hover .ant-radio,
.ant-radio:hover .ant-radio-inner,
.ant-radio-input:focus + .ant-radio-inner {
  border-color: #000 !important;
}
.textRadio {
  color: var(--text-primary, rgba(0, 0, 0, 0.87));
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 16px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
  letter-spacing: 0.15px;
}
.ant-drawer-wrapper-body {
  background: rgba(246, 246, 246, 1);
}
.ant-drawer-body {
  margin-top: -20px !important;
}
.drawerHeading {
  color: var(--Gray-1, #333);
  font-family: 'Circular-500' !important;
  font-size: 22px;
  font-style: normal;
  /* font-weight: 600; */
  line-height: normal;
  margin-left: 10px !important;
  word-spacing: -4px !important;
}
.unpublished-row .ant-typography {
  color: #7b7b7b !important;
}
/* .ant-upload.ant-upload-select-picture-card {
  width: 100px !important;
  height: 0px !important;
  margin-top: 50px;
} */
.ant-picker {
  border: 1px solid #7b7b7b !important;
  background: #f6f6f6 !important;
}
.ant-table-body {
  overflow: hidden !important;
}
.ant-table-column-sort {
  background-color: #f6f6f6 !important;
}
.asterisk {
  color: #eb5757;
  position: absolute;
  font-size: 14px;
}
.loading {
  font-size: 20px;
  font-weight: 800;
  animation: spin 1s infinite linear;
}

.days {
  font-family: 'Circular-400' !important;
  font-size: 15px;
}

.timing {
  font-family: 'Circular-900' !important;
  word-spacing: -5px;
}

.daysContainer {
  margin-top: 17px !important;
}

.timeContainer {
  margin-right: 8px;
}
.categoryItemButton {
  background: #ffffff !important;
  color: #262626 !important;
  /* border-radius: 5.191px !important; */
  /* border: 0.727px solid #262626 !important; */
  /* border: 1px solid white !important; */
  border: none !important;
  font-weight: 450 !important;
  font-family: 'Circular-400' !important;
  /* text-align: center !important; */
  /* width: 110px; */
}

.ant-popover-inner-content {
  padding: 7px !important;
  border-radius: 5px;
  margin-top: -25px !important;
}
.ant-popover-arrow,
.ant-popover-arrow-content {
  display: none !important;
}
.ant-popover-placement-top {
  top: 186px !important;
}
.ant-input-prefix {
  color: #a1a1a0 !important;
  margin-right: 10px !important;
}
.ant-input-affix-wrapper-lg {
  background-color: rgb(246, 246, 246) !important;
}
.ant-input-affix-wrapper {
  background-color: #f6f6f6 !important;
  border-radius: 5px !important;
  border-color: #a1a1a0 !important;
}

.ant-input-search-button {
  background-color: rgb(246, 246, 246) !important;
}

.uploadText {
  margin-top: -6px;
  color: #7b7b7b !important;
  font-family: 'circular-400' !important;
  font-size: 15px !important;
  word-spacing: -5px;
}

.imageType {
  color: #7b7b7b !important;
  font-family: 'circular-700' !important;
  font-size: 12px !important;
  word-spacing: -4px;
}
.svgImage {
  width: 100px !important;
  /* height: 90px !important; */
  padding-top: 10px !important;
}
.editIcon {
  position: relative;
  top: 10% !important;
  right: 10%;
}
.deleteIcon {
  margin-right: 5px !important;
  margin-bottom: 3px;
}
.ant-row-space-between {
  justify-content: initial !important;
}
.showSpace {
  display: flex !important;
  justify-content: space-between !important;
  align-content: center !important;
  float: right !important;
}
.detailingButton {
  width: 100% !important;
  border-radius: 5.191px !important;
  border: 0.727px solid #262626 !important;
  background: #262626 !important;
  color: #f6f6f6 !important;
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
}

/** Customer css **/
.customers {
  margin-top: 35px;
  border-bottom: 1px solid #dddbdb;
  padding-bottom: 30px;
}
.subHeading {
  overflow: hidden !important;
  color: #262626 !important;
  text-overflow: ellipsis !important;
  font-family: 'Circular-400' !important;
  font-size: 24px !important;
  font-style: normal !important;
  font-weight: 450 !important;
  line-height: normal !important;
  margin-top: 18px !important;
}
.customerDetails {
  border-right: 1px solid #dddbdb !important;
  margin-right: 7px !important;
}
.customerData {
  color: #262626 !important;
  font-family: 'Circular-400' !important;
  font-size: 15px;
  font-style: normal;
  /* line-height: 150%; */
  letter-spacing: 0.15px;
  word-spacing: -5px;
  margin-bottom: 10px;
}
.customerInfoHeading {
  color: #262626 !important;
  font-family: 'Circular-400' !important;
  font-size: 15px;
  word-spacing: -5px;
  margin-bottom: -5px;
}
.customerHeading {
  color: #262626 !important;
  font-family: 'Circular-900' !important;
  font-size: 14px;
  font-style: normal;
  line-height: 150%;
  letter-spacing: 0.15px;
  word-spacing: -5px;
}
.customerInformation {
  border: 1px solid #dddbdb;
  padding: 15px;
  border-radius: 5px;
}
.customerAddressType {
  padding-top: 30px !important;
  font-family: 'Circular-700' !important;
  font-size: 16px !important;
}
.customerFullAddress {
  font-family: 'Circular-900' !important;
  font-size: 15px !important;
  word-spacing: -5px !important;
  padding-bottom: 30px !important;
  border-bottom: 1px solid #b6b6b6 !important;
}
.customerAddressHeader .ant-drawer-header {
  border-bottom: 2px solid #b6b6b6 !important;
}
.ordersTable {
  border: 1px solid #dddbdb;
  padding: 15px;
  border-radius: 5px;
}
.orderButton {
  border-radius: 5.191px !important;
  border: 0.727px solid #262626 !important;
  background: #262626 !important;
  color: #f6f6f6 !important;
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
}
.orderButton2 {
  border: none !important;
  background: #f6f6f6 !important;
  color: #262626 !important;
  text-align: center !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
}
.odrersStatus {
  border: 1px solid #7b7b7b;
  border-radius: 20px !important;
  padding: 6px 10px !important;
}
.circleIcon {
  font-size: 10px;
  margin-right: 5px;
}
.selectOptions {
  font-family: 'circular-700';
  word-spacing: -5px;
  color: #262626;
}
.rdrCalendarWrapper {
  font-family: 'circular-700';
  background-color: rgb(246, 246, 246) !important;
}
.rdrMonthAndYearPickers select {
  font-family: 'circular-700';
  background-color: rgb(246, 246, 246) !important;
}
.rdrStaticRangeLabel {
  font-family: 'circular-700';
  background-color: rgb(246, 246, 246) !important;
}
.rdrInputRangeInput {
  background-color: rgb(246, 246, 246) !important;
}
.rdrDefinedRangesWrapper {
  font-family: 'circular-700';
  background-color: rgb(246, 246, 246) !important;
}
.rdrDateRangePickerWrapper {
  border: 1px solid #8d8b8b !important;
  border-radius: 3px !important;
}
.rdrDateInput {
  background-color: rgb(246, 246, 246) !important;
}
.rdrDay {
  font-family: 'circular-700';
  background-color: rgb(246, 246, 246) !important;
}
.ant-collapse-header {
  background-color: #f6f6f6 !important;
  border-radius: 5px !important;
}
.ant-collapse {
  border-radius: 5px !important;
}
.ant-collapse-content {
  border: none !important;
  background-color: #f6f6f6 !important;
}
.changeUnavailabilityValue {
  border: 1px solid #7b7b7b;
  padding: 6px;
  font-family: 'Circular-700';
  font-size: 15px;
  word-spacing: -3px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.changeUnavailabityTrue {
  opacity: 0.5;
  pointer-events: none;
}
.ququeButtonContainer {
  margin: 20px 0px !important;
}
.queueActivityContainer {
  border: 1px solid #c0bebe !important;
  border-radius: 5px !important;
  padding: 10px !important;
}
.queueActivityDivider {
  background-color: #c0bebe !important;
  margin: 15px 0px !important;
}
.queueActivityText {
  font-size: 14px !important;
  font-family: 'Circular-400' !important;
  color: #7b7b7b !important;
  word-spacing: -4px !important;
}
.queueActivitydetails {
  font-family: 'Circular-400' !important;
  font-style: normal !important;
  font-size: 14px !important;
  line-height: 20px !important;
  color: #262626 !important;
  word-spacing: -4px !important;
  letter-spacing: -0.3px !important;
}
.queueActivitySpace {
  margin-bottom: 8px !important;
}
.ant-timeline-item-tail {
  display: block !important;
  border-left: 2px solid #262626 !important;
  top: 11px !important;
}
.ant-timeline-item-head-custom {
  padding: 0 !important;
}
.hideTail .ant-timeline-item-tail {
  display: none !important;
}
.last-timeline-item {
  padding-bottom: 0 !important;
}
.queueActivityTextWrap {
  text-wrap: nowrap !important;
}

/* Customization css */

.customization-tab .ant-tabs-tab-active {
  background-color: #262626 !important;
  border-radius: 5px !important;
}
.customization-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f6f6f6 !important;
}

.customization-tab .ant-tabs-nav {
  font-family: 'Circular-400' !important;
  color: #262626 !important;
  background-color: #f6f6f6 !important;
  border: none !important;
  margin-bottom: -7px !important;
}

.customization-tab .ant-tabs-tab-btn:hover {
  color: #262626 !important;
}

.customization-tab .ant-tabs-nav .ant-tabs-tab:not(.ant-tabs-tab-active) {
  background: transparent !important;
  border: none !important;
}

/* Customization Item css */

.customizationItemChangeUnavailability .ant-tabs-tab-btn:hover {
  color: #262626 !important;
}
.customizationItemChangeUnavailability .ant-tabs-nav {
  font-family: 'Circular-400' !important;
  color: #262626 !important;
  background-color: #f6f6f6 !important;
  border: none !important;
}
.customizationItemChangeUnavailability .ant-tabs-tab {
  border: none !important;
}
.customizationItemChangeUnavailability
  .ant-tabs-tab.ant-tabs-tab-active
  .ant-tabs-tab-btn {
  color: #f6f6f6 !important;
}
.customizationItemChangeUnavailability .ant-tabs-tab-active {
  background-color: #262626 !important;
}
.customizationItemChangeUnavailability .ant-tabs-nav {
  margin-bottom: 0px !important;
}
.customizationItemChangeUnavailability .ant-tabs-tab {
  padding-left: 35px !important;
  width: 25% !important;
  overflow: hidden !important;
}
.customizationItemChangeUnavailability .ant-tabs-nav-more {
  display: none !important;
}
.customizationItemChangeUnavailability .ant-tabs-nav-list {
  overflow: hidden !important;
}
.customizationItemChangeUnavailability .ant-tabs-nav .ant-tabs-tab {
  flex: none;
}
.customizationItemNote {
  font-family: 'Circular-400';
  opacity: 0.6;
  word-spacing: -5px;
  margin-top: 20px;
}

/* Branding css */

.branding-tab .ant-tabs-nav {
  font-family: 'Circular-400' !important;
  word-spacing: -4px !important;
}

.branding-tab .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #494949 !important;
}
.branding-tab .ant-tabs-tab-active {
  border-bottom: 2px solid #858484 !important;
  color: #262626 !important;
}
.branding-tab .ant-tabs-tab:hover {
  color: #262626 !important;
}
.branding-tab .ant-tabs-tab {
  opacity: 0.9;
}
.branding-tab.ant-tabs-top > .ant-tabs-nav::before {
  border-bottom: 2px solid #dbdbdb !important;
}
#upload-input {
  display: none;
}
/* Circular preview image */
.image-preview {
  width: 100px;
  height: 100px;
  overflow: hidden;
  margin-top: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f0f0f0;
  margin-left: 15px;
}
.image-preview img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
/* Custom label to trigger file input */
.upload-label {
  cursor: pointer;
  position: absolute;
  top: 80%;
  right: -19px;
}
.onlineStoreImageRemove {
  margin-left: 40px;
  font-family: 'Circular-900';
  color: blue !important;
  text-decoration: underline;
  cursor: pointer;
}
.addImage {
  margin-top: 10px;
  cursor: pointer;
  border: 1px solid #7b7b7b;
  font-family: 'Circular-900';
  padding: 7px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  word-spacing: -5px;
  line-height: 22px;
}
.appClipDeleteLogo {
  position: absolute;
  cursor: pointer;
  top: -10%;
  left: 90%;
}
.appClipFooterHeading {
  font-size: 17px;
  font-family: 'Circular-500';
}
.appClipFooterText {
  font-family: 'Circular-900';
  word-spacing: -5px;
}
.appClipButton {
  border-radius: 25px !important;
  background: #262626 !important;
  color: #f6f6f6 !important;
  text-align: center !important;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  height: 45px !important;
}
.appClipFootertext2 {
  font-family: 'Circular-900' !important;
  font-size: 13px !important;
  color: #7b7b7b !important;
  word-spacing: -5px;
  line-height: 18px;
}
.appClipFooterName {
  font-family: 'Circular-400' !important;
  color: #262626 !important;
  position: relative;
}
.appClipFooterDefaultImage {
  background: grey;
  opacity: 0.9;
  width: 100%;
  height: 100%;
  display: flex;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  justify-content: center;
  align-items: center;
}
.appClipFooterContainer {
  padding: 15px;
  background: #ece7e7;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
}
.appClipFooterBanner {
  position: absolute;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  width: 100%;
  height: 100%;
}
.instantAppHeader {
  background: grey;
  padding: 2px 10px;
  color: white !important;
}
.instantAppFootertext {
  margin: 160px 0px 30px 0px;
  text-align: center;
  font-family: 'Circular-500';
  word-spacing: -5px;
  font-size: 16px;
}

/** Category Item Css **/

.categoryItemHeading {
  color: #262626 !important;
  font-family: 'Circular-500' !important;
  font-size: 19px !important;
  line-height: normal !important;
  word-spacing: -5px;
}
.detailsubheading2 {
  color: #262626 !important;
  font-family: 'Circular-900' !important;
  font-size: 15px !important;
  line-height: normal !important;
  word-spacing: -4px;
}
.categoryItemDetailsType {
  opacity: 0.8;
  font-style: italic;
  line-height: normal !important;
  font-family: 'Circular-900' !important;
  word-spacing: -4px;
}
.categoryItemDetailsMax {
  margin-top: 10px;
  font-family: 'Circular-900' !important;
  line-height: normal !important;
  opacity: 0.8;
  word-spacing: -4px;
}
.customizationItemDetails {
  color: #262626 !important;
  font-family: 'Circular-900' !important;
  font-size: 14px !important;
  line-height: 25px !important;
  word-spacing: -4px;
}
.tablesIcon {
  cursor: pointer;
  position: relative;
  top: 15%;
  padding: 0px 5px 0px 5px;
}

/** Survey Question **/

.centerItem {
  display: flex !important;
  align-items: center !important;
  text-decoration: underline !important;
  text-wrap: nowrap !important;
  cursor: pointer;
}

.fileUpload .ant-upload.ant-upload-select-picture-card {
  width: 100% !important;
}

.uploadTextLine {
  font-family: 'Circular-400' !important;
  word-spacing: -5px !important;
  line-height: 18px !important;
}
.uploadTextLine2 {
  font-family: 'Circular-400' !important;
  word-spacing: -5px !important;
  line-height: 18px !important;
  padding-top: 5px !important;
  color: #7b7b7b !important;
}
.customRate .ant-rate-star svg {
  stroke: black !important;
  stroke-width: 20 !important;
}

/** Order Process Notifications **/

.orderClips {
  cursor: pointer !important;
  background-color: #00000014 !important;
  font-family: 'Circular-700' !important;
  text-align: center !important;
  padding: 5px !important;
  margin: 5px !important;
  border-radius: 20px !important;
}
.ant-tag-checkable-checked {
  color: #f6f6f6 !important;
  background-color: #219653 !important;
}

.dawnButton.ant-btn {
  background: rgb(246, 246, 246) !important;
  border: none;
}
.dawnButton.ant-btn:hover,
.ant-btn:focus {
  border-color: black;
}
.switchButton.ant-switch-checked {
  background-color: #000;
}
.centerText {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

/** Queue Layout **/

.custom-tabs .ant-tabs-tab {
  margin: 0 !important;
  font-family: 'Circular-900' !important;
  background: #f6f6f6;
  color: #000;
  padding: 8px 16px;
  transition: none;
}

.custom-tabs .ant-tabs-nav {
  margin-bottom: 0 !important;
}

.custom-tabs .ant-tabs-tab-active {
  background: #262626;
  color: #f6f6f6 !important;
}

.custom-tabs .ant-tabs-tab:hover {
  background: #f6f6f6;
  color: #000;
}

.custom-tabs .ant-tabs-tab-active:hover {
  background: #262626;
  color: #f6f6f6 !important;
}
.custom-tabs .ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: #f6f6f6 !important;
}
.table-card {
  display: inline-block;
  width: 80px;
  text-align: center;
  margin: 30px 20px;
  cursor: pointer;
}
.custom-card {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
  overflow: hidden;
}

.card-header {
  color: #f6f6f6;
  font-family: 'Circular-900';
  display: flex;
  padding: 3px;
  justify-content: center;
  align-items: center;
}

.card-content {
  /* display: flex !important;
  justify-content: center !important;
  align-items: center !important; */
  background: #262626;
  color: #f6f6f6;
  font-family: 'Circular-900';
  padding: 10px;
  border-bottom-right-radius: 5px !important;
  border-bottom-left-radius: 5px !important;
  height: 60px !important;
}

.noteText{
  color: #262626 !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
  letter-spacing: 0.15px;
  word-spacing: -5px;
}

.infoText{
  color: #7b7b7b !important;
  font-feature-settings: 'clig' off, 'liga' off;
  font-family: 'Circular-400' !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 450;
  line-height: 150%;
  letter-spacing: 0.15px;
  word-spacing: -5px;
}

